/****** Hyundai Sans Head *******/
@font-face {
    font-display: swap;
    font-family: 'HyundaiSansHead-Bold';
    unicode-range: U+000-5FF;
    src:
        local('HyundaiSansHead-Bold'),
        url('/assets/fonts/HyundaiSansHead-Bold.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansHead-Bold.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'HyundaiSansHead-Light';
    unicode-range: U+000-5FF;
    src:
        local('HyundaiSansHead-Light'),
        url('/assets/fonts/HyundaiSansHead-Light.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansHead-Light.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'HyundaiSansHead-Medium';
    unicode-range: U+000-5FF;
    src:
        local('HyundaiSansHead-Medium'),
        url('/assets/fonts/HyundaiSansHead-Medium.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansHead-Medium.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'HyundaiSansHead-Regular';
    unicode-range: U+000-5FF;
    src:
        local('HyundaiSansHead-Regular'),
        url('/assets/fonts/HyundaiSansHead-Regular.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansHead-Regular.woff') format('woff');
}

/****** Hyundai Sans Text *******/
@font-face {
    font-display: swap;
    font-family: 'HyundaiSansText-Bold';
    unicode-range: U+000-5FF;
    src:
        local('HyundaiSansText-Bold'),
        url('/assets/fonts/HyundaiSansText-Bold.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansText-Bold.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'HyundaiSansText-BoldItalic';
    unicode-range: U+000-5FF;
    src:
        local('HyundaiSansText-BoldItalic'),
        url('/assets/fonts/HyundaiSansText-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansText-BoldItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'HyundaiSansText-Italic';
    unicode-range: U+000-5FF;
    src:
        local('HyundaiSansText-Italic'),
        url('/assets/fonts/HyundaiSansText-Italic.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansText-Italic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'HyundaiSansText-Medium';
    unicode-range: U+000-5FF;
    src:
        local('HyundaiSansText-Medium'),
        url('/assets/fonts/HyundaiSansText-Medium.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansText-Medium.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'HyundaiSansText-Regular';
    unicode-range: U+000-5FF;
    src:
        local('HyundaiSansText-Regular'),
        url('/assets/fonts/HyundaiSansText-Regular.woff2') format('woff2'),
        url('/assets/fonts/HyundaiSansText-Regular.woff') format('woff');
}
