$header-height-mobile: 7.8rem;
$header-height-desktop: 8rem;
$scrollbar-thumb-bg: #325680;
$model-page-header-border-color: rgba($white, 0.1);
$model-nav-padding-vertical-desktop: 1.9rem;
$model-nav-padding-vertical-fullhd: 1.9rem;
$model-nav-padding-vertical: 0;
$sub-nav-cta-font-weight-key: medium;
$map-phone-title-family-key: secondary;
$map-phone-title-color: $brownish-grey;

/****** BREADCRUMB INDENTS *******/
$breadcrumb-indent-mobile: 0;
$breadcrumb-indent-mobile-small: 20px;
$breadcrumb-indent-tablet: 20px;
$breadcrumb-indent-desktop: 15px;
$breadcrumb-indent-widescreen: 25px;
$breadcrumb-indent-fullhd: 27px;
$breadcrumb-indent-fullwidth: 27px;

$breadcrumb-border-color: $color-lightgrey;
