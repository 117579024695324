$footer-background-color: $black-bis;
$footer-divider: #2f2e2e;
$footer-border: 1px solid $footer-divider;
$footer-navbar-item: $grey-dark;
$footer-navbar-item-hover: lighten($grey-dark, 20%);
$footer-icon-color: $white;

ncg-footer {
    .footer {
        background-color: $footer-background-color;

        &-top {
            padding-top: 4rem;

            @include until($desktop) {
                padding-top: 1rem;
            }

            &__columns {
                @include until($desktop) {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }

            &__column {
                @include until($desktop) {
                    padding-bottom: 2rem;
                    padding-left: 0;
                    padding-right: 0;
                    border-bottom: $footer-border;
                }
            }

            &__menu-label {
                flex: 1 0 auto;
                font-family: map-get($font-families, medium);

                @include from($desktop) {
                    padding-bottom: 1rem;
                }

                a {
                    color: $footer-navbar-item;
                    text-decoration: none;

                    &:hover {
                        color: $footer-navbar-item-hover;
                    }
                }
            }

            &__menu-list {
                a {
                    @include typeset(sub);
                    padding: 0.6rem 0;
                    line-height: 1.2;
                    letter-spacing: map-get($letter-spacings, large);
                    color: $footer-navbar-item;
                    text-decoration: none;

                    &:hover {
                        color: $footer-navbar-item-hover;
                    }
                }
            }
        }

        &-bottom {
            @include until($desktop) {
                flex-direction: column;
                padding-top: 0;

                > .container {
                    flex-direction: column;
                }
            }

            @include from($desktop) {
                padding-top: 3rem;
                border-top: $footer-border;
            }

            &__logo {
                @include until($desktop) {
                    order: 1;
                    align-self: center;
                    padding-bottom: 2.5rem;
                }

                @include from($desktop) {
                    padding-right: 5rem;
                    padding-left: 1rem;
                    padding-top: 1rem;
                }

                svg {
                    width: 6rem;
                    height: 3.1rem;
                }
            }

            &__links {
                @include until($desktop) {
                    order: 3;
                }
                @include desktop {
                    nav {
                        flex-wrap: wrap;
                    }
                }
            }

            &__navbar {
                @include until($desktop) {
                    display: flex;
                    flex-flow: wrap row;
                    align-items: center;
                    white-space: nowrap;
                    justify-content: center;

                    .navbar-item {
                        font-size: map-get($font-sizes, x-small);
                    }
                }

                .navbar-item {
                    color: $footer-navbar-item;

                    &:hover {
                        color: $footer-navbar-item-hover;
                    }
                }
            }

            &__item {
                &:hover {
                    color: lighten($grey-dark, 50%);
                }

                &--divider {
                    &::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        height: 60%;
                        width: 1px;
                        background-color: $footer-divider;
                    }

                    &:last-child {
                        &::after {
                            content: none;
                        }
                    }
                }
            }

            &__social {
                @include until($desktop) {
                    order: 2;
                    padding-bottom: 2.5rem;
                }

                .footer-bottom {
                    &__item {
                        color: $footer-navbar-item;

                        @include until($desktop) {
                            margin: 0 1rem;
                        }

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }

            &__copyright {
                @include typeset(small);
                padding-left: 1rem;
                color: $footer-navbar-item;

                @include until($desktop) {
                    padding-top: 2rem;
                    font-size: map-get($font-sizes, x-small);
                    text-align: center;
                }
            }
        }
    }
}
